
import { Holistic } from '@mediapipe/holistic';

// model state : loading, ready, closing, closed
class ModelMgr {
  static instance = null;
  static get() {
    if (!ModelMgr.instance) {
      const model_path = `${process.env.PUBLIC_URL}/model/holistic/`;
      ModelMgr.instance = new ModelMgr(model_path);
    }
    return ModelMgr.instance;
  }    
  static stateCb = (state) => {console.error('ModelMgr stateCb not implemented!!!');};
  static inferCb = (results) => {console.error('ModelMgr inferCb not implemented!!!');};
  static setCb(state_cb, infer_cb) {
    ModelMgr.stateCb = state_cb;
    ModelMgr.inferCb = infer_cb;
    ModelMgr.get().model.onResults(ModelMgr.inferCb);
  }

  constructor(model_path){
    console.log('ModelMgr constructor');
    this.model = new Holistic({
        locateFile: (file) => {
          var url = model_path + file;
          return url;
        }
    });
    // export interface Options {
    //   enableFaceGeometry?: boolean;
    //   selfieMode?: boolean;
    //   modelComplexity?: 0|1|2;
    //   smoothLandmarks?: boolean;
    //   enableSegmentation?: boolean;
    //   smoothSegmentation?: boolean;
    //   refineFaceLandmarks?: boolean;
    //   minDetectionConfidence?: number;
    //   minTrackingConfidence?: number;
    // }
    this.model.setOptions({
        enableFaceGeometry:true,
        minDetectionConfidence: 0.5,
        minTrackingConfidence: 0.5,
    });
    this.model.onResults(ModelMgr.inferCb);
    this.model.initialize().then(()=>{
        console.log('ModelMgr model initialized');
        ModelMgr.stateCb('ready');
    });
    ModelMgr.stateCb('loading');
  }

  finalize() {
    console.log("ModelMgr finalize");
    this.model.close().then(()=>{
      delete this.model;
      console.log('ModelMgr model closed.');
      ModelMgr.stateCb('closed');
    });
    ModelMgr.stateCb('closing');
  }

  infer(frame) {
    this.model.send({ image: frame}).then(()=>{
      // console.log('ModelMgr process frame done');
    });
  }
  // async infer(frame) {
  //   await this.model.send({ image: frame});
  // }
}

export default ModelMgr;
