
import React from 'react';
import PropTypes from 'prop-types';
import styles from './Monitor.module.css';

import Controler from './Controler';
import Detector from './Detector';

class Monitor extends React.Component{
  static propTypes = {
    hide: PropTypes.bool,
    onState: PropTypes.func,
    onMsg: PropTypes.func.isRequired
  }
  static defaultProps = {
    hide: false,
    onState: (state) => {},
    onMsg: (msg) => { console.error('Monitor onMsg not implemented!!!');}
  }

  constructor(props){
    super(props);
    this.state = {
      video_stream: null,
      video_file: null,
      interval: 100,
      preview: true,
      debu_face_idx: -1,
      model_state: 'loading',
    };
  }

  onStateUpdat = (modelState,detectorState) => {
    // console.log('monitor state:',modelState + '_' + detectorState);
    this.setState({model_state: modelState + '_' + detectorState});

    if (modelState === 'loading') {
      this.props.onState('model');
    } 
    if (modelState && modelState !== 'loading') {
      this.props.onState(null);
    }
  }
  onIntervalUpdate = (evt) => {
    let interval = parseInt(evt.currentTarget.value);
    // console.log('onIntervalUpdate:',interval);
    if (interval <= 0 || interval > 1000) {
      console.error('interval out of range:',interval);
      return;
    }
    this.setState({interval:interval});
  }
  onVideoUpdate = (stream,file) => {
    this.setState({video_stream:stream,video_file:file});
  }

  switchPreview = () => {
    this.setState({preview:!this.state.preview});
  }
  setDebugFaceIdx = (idx) => {
    this.setState({debu_face_idx:idx});
  }
  
  render() {
    let sorh = styles.monitor_show;
    if (this.props.hide) {
      sorh = styles.monitor_hide
    }
    return (
        <div className={styles.monitor_container + " " +sorh}>
            <Controler modelState={this.state.model_state} 
                      interval={this.state.interval}
                      preview={this.state.preview}
                      setIntervalCb={this.onIntervalUpdate}
                      setVideoCb={this.onVideoUpdate}
                      setPreviewCb={this.switchPreview} 
                      faceIdxCb={this.setDebugFaceIdx}/>
            <Detector stream={this.state.video_stream} debug={this.state.debu_face_idx}
                      file={this.state.video_file} 
                      interval={this.state.interval}
                      preview={this.state.preview && !this.props.hide} 
                      stateCb={this.onStateUpdat}
                      inferCb={this.props.onMsg}/> 
        </div>
    );
  }

}

export default Monitor;
