
import React from 'react';
import PropTypes from 'prop-types';
import { VERSION } from '@mediapipe/holistic';
import styles from './Monitor.module.css';

class Controler extends React.Component{
  static propTypes = {
    debug: PropTypes.bool,
    preview: PropTypes.bool,
    interval: PropTypes.number,
    modelState: PropTypes.string.isRequired,
    setVideoCb: PropTypes.func.isRequired,
    setIntervalCb: PropTypes.func,
    setPreviewCb: PropTypes.func,
    faceIdxCb: PropTypes.func
  }
  static defaultProps = {
    debug: false,
    interval: 100,
    preview: false,
    setPreviewCb: () => {}
  }

  constructor(props){
    super(props);
    this.state = { video_source : 'no video', face_index: ''};
    
    this.camera_stream = null;
  }
  componentWillUnmount() {
    if (this.camera_stream) {
      this.camera_stream.getTracks().forEach(track => {
        track.stop();
      });
    }
    this.camera_stream = null;
  }
  
  onCamera = (e)=>{
    console.log('onCamera',this.props,this.state);
    const constraints = {
      video: { width: { min: 1280 }, height: { min: 720 } }
    };
    navigator.mediaDevices.getUserMedia(constraints).then((stream) => {
      this.camera_stream = stream;
      this.props.setVideoCb(stream, null);
      this.setState({video_source:'camera'});
    });
  }

  onFileOpen = (e)=>{
    console.log('onFileOpen',this.props,this.state);
    var file_brower = document.getElementById("controler-video-file");
    if(file_brower){
      file_brower.value = ''; // clear file input
      file_brower.click();
    } 
  }

  onFileChange = (evt) => {
    const newpath = evt.currentTarget.value;
    const newfile = newpath.substring(newpath.lastIndexOf('\\') + 1);
    console.log('onFileChange',newpath, newfile);
    this.props.setVideoCb(null, newfile);
    this.setState({video_source:newfile});
    if (this.camera_stream) {
      this.camera_stream.getTracks().forEach(track => {
        track.stop();
      });
    }
    this.camera_stream = null;
  }

  onStop = (e) => {
    this.props.setVideoCb(null, null);
    this.setState({video_source:'no video'});
    if (this.camera_stream) {
      this.camera_stream.getTracks().forEach(track => {
        track.stop();
      });
    }
    this.camera_stream = null;
  } 
  
  onFaceIdx = (e) => {
    let new_face_index = parseInt(e.currentTarget.value);
    if (!new_face_index) {
      new_face_index = 0;
    }
    this.setState({face_index:new_face_index});
    if (this.props.faceIdxCb) {
      this.props.faceIdxCb(new_face_index);
    }
    console.log('onFaceIdx',new_face_index);
  }

  render() {
    return (
      <div className={styles.controler_container}>
        <br/>
        <ul className={styles.controler_list}>
            <img className={styles.controler_badge} src={`${process.env.PUBLIC_URL}/image/lite-badge.jpg`} alt="nvidia lite badge"/>
            <br/>
            <li><p>model:{VERSION}</p></li>
            <br/>
            <li><input onChange={this.onFileChange} type='file' id={styles.video_file}/></li>
            <li><label>video: {this.state.video_source}</label></li>
            <li><label>state: {this.props.modelState}</label></li>
            <li>
              <label htmlFor="interval">interval:{this.props.interval}ms</label>
              <input type="range" min="10" max="1000" step="10" id="interval"
                value={this.props.interval} onInput={this.props.setIntervalCb} 
                style={{ width: '80%'}}/>
            </li>
            <br/>
            <li>
              <button onClick={this.onCamera} className={styles.camera}>camera</button>|
              {this.props.debug && <button onClick={this.onFileOpen}>video file</button>}
              <button onClick={this.onStop}>stop</button>|
              <button onClick={this.props.setPreviewCb}>
                {this.props.preview?"noview":"preview"}
              </button>
            </li>
            <br/>
            {this.props.debug && <li>
              <input onChange={this.onFaceIdx} placeholder='find face index' value={this.state.face_index}/>
            </li>}
        </ul>
      </div> );
  }


}

export default Controler;
