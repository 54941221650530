
import React from "react";


const sysConfigContext = React.createContext();


class SysConfProvider extends React.Component {
    state = {
        theme: 'light',
        language: 'chinese',
        immerse: false
    };
  
    updateState = (newConf) => {
        // console.log('update sysConf from :',this.state,',to:',newConf);
        this.setState(newConf);
    };
  
    render() {
        const sysConf = this.state;
        const updateSysConf = this.updateState;
      return (
        // <sysConfigContext.Provider value={{...sysConf , updateSysConf}}>
        <sysConfigContext.Provider value={{sysConf , updateSysConf}}>
          {this.props.children}
        </sysConfigContext.Provider>
      );
    }
  }
  
  export const asSysConfProvider = (WrappedComponent) => {
    return class extends React.Component {
      render() {
        return (
          <SysConfProvider>
            <WrappedComponent/>
          </SysConfProvider>
        );
      }
    };
  };

  export const asSysConfConsumer = (WrappedComponent) => {
    return class extends React.Component {
      render() {
        return (
          <sysConfigContext.Consumer>
            {(context) => <WrappedComponent {...this.props} {...context} />}
          </sysConfigContext.Consumer>
        );
      }
    };
  };

  