import React from 'react';
import { asSysConfConsumer } from '../utility/hoc/Sysconf';
import styles from '../style/Footer.module.css';

//Copy Right ©️ 2017-2022 灵起科技
// 京ICP备17061512号 & 京公网安备11011502003341号

class Footer extends React.Component {
    render() { 
        if (this.props.sysConf.immerse) {
            return null;
        }
        return (
            <div className={styles.container}>
                <footer className={styles.footer}>
                    <p className={styles.copyright}>&copy; 2024 灵起科技. All rights reserved.</p>
                    
                    <p className={styles.copyright}>
                        <a className={styles.beian} href='https://beian.miit.gov.cn/#/Integrated/index'>
                            <img className={styles.beianpic} src={`${process.env.PUBLIC_URL}/image/beian.png`}
                            alt=""/>京ICP备17061512号
                        </a> &nbsp;&&nbsp;
                        <a className={styles.beian} href='http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11011502003341'>
                            京公网安备11011502003341号
                        </a>
                    </p>
                </footer>
            </div>
        );
    }
}

export default asSysConfConsumer(Footer);
