import React from 'react';
import styles from '../../style/solution/LLM.module.css';

function SolutionLlm() {
    return (
        <section className={styles.container} >
            <iframe className={styles.pptframe} 
                src={`${process.env.PUBLIC_URL}/solutions/LLM/index.html`}
                title="LLM solution introduce pages"
            />
        </section>
    );
}

export default SolutionLlm;
