import React from 'react';
import styles from '../../style/solution/CV.module.css';

class SolutionCv extends React.Component {
    render() {
        return (
            <section className={styles.container}>
                <iframe className={styles.pptframe} id="cv-iframe"
                    src={`${process.env.PUBLIC_URL}/solutions/CV/index.html`}
                    title="CV solution introduce pages"
                />
            </section>
        );
    }
}

export default SolutionCv;
