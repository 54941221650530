import React from 'react';
import { useLocation, useParams, useNavigate } from 'react-router-dom';

const withRouterInfo = (WrappedComponent) => {
  return (props) => {

    const base = process.env.PUBLIC_URL;
    const location = useLocation();
    const params = useParams();
    const navigate = useNavigate();

    const route = {base,location,params,navigate};
    
    return (
      <WrappedComponent
        {...props}
        route={route}
      />
    );
  };
};

export default withRouterInfo;
