
import React from 'react';
import PropTypes from 'prop-types';
import { POSE_CONNECTIONS,FACEMESH_TESSELATION,HAND_CONNECTIONS } from '@mediapipe/holistic';
import { drawConnectors,drawLandmarks } from '@mediapipe/drawing_utils';
import ModelMgr from './ModelMgr.js';
import styles from './Monitor.module.css';

class Detector extends React.Component{
  static propTypes = {
    interval: PropTypes.number, // detector interval in ms
    stream: PropTypes.object,
    file: PropTypes.string,
    preview: PropTypes.bool,
    debug: PropTypes.number,
    stateCb: PropTypes.func.isRequired,
    inferCb: PropTypes.func.isRequired
  }
  static defaultProps = {
    interval: 100,
    stream: null,
    file: null,
    preview: true,
    debug: -1
  }

  constructor(props){
    super(props);
    this.videoRef = React.createRef();
    this.canvasRef = React.createRef();

    this.model_state = 'unknow';
    this.detector_state = 'unknow';

    console.log('Detector constructor');
  }
  componentDidUpdate(prevProps, prevState) {
    console.log('Detector componentDidUpdate from:',prevProps, prevState,
          'to:',this.props, this.state);
    if (!this.props.preview) {
      this.clearPreview();
    }
    if (this.props.interval !== prevProps.interval) {
      if( this.detecte_timer ){
        clearInterval(this.detecte_timer);
        this.detecte_timer = setInterval(this.detecte_video, this.props.interval);
      }
    }
    if (this.props.stream !== prevProps.stream || this.props.file !== prevProps.file) {
      this.setSource(this.props.stream, this.props.file);
    }
    if((this.props.stream || this.props.file) && !this.detecte_timer ) {
      this.setSource(this.props.stream, this.props.file);
    }
  }

  componentWillUnmount() {
      console.log('Detector componentWillUnmount');
      if( this.detecte_timer ){
        clearInterval(this.detecte_timer);
        this.detecte_timer = null;
      }
  }

  componentDidMount() {
      console.log('Detector componentDidMount',this.props,this.state);
      this.onUpdateState(null,'ready');
      ModelMgr.setCb(this.onUpdateState, this.onInferResults);
  } 
  
  clearPreview () {
      if (!this.canvasRef.current) 
        return;
      const context = this.canvasRef.current.getContext('2d');
      if (!context) 
        return;
      context.save();
      context.clearRect(0,0,
        this.canvasRef.current.width,
        this.canvasRef.current.height
      );
      context.restore();
  }

  drawPreview (results) {
      if (!this.props.preview || !results) 
        return;
      if (!this.canvasRef.current) 
        return;
      const context = this.canvasRef.current.getContext('2d');
      if (!context) 
        return;
      context.save();
      context.clearRect(0,0,
        this.canvasRef.current.width,
        this.canvasRef.current.height
      );

      context.drawImage(
        results.image,0,0,
        this.canvasRef.current.width,
        this.canvasRef.current.height
      );
      // console.log(results)
      drawConnectors( context, results.poseLandmarks, POSE_CONNECTIONS,
                {color: '#00FF00', lineWidth: 4});
      drawLandmarks( context, results.poseLandmarks,
                {color: '#FF0000', lineWidth: 2});
      drawConnectors( context, results.faceLandmarks, FACEMESH_TESSELATION,
                {color: '#C0C0C070', lineWidth: 1});
      drawConnectors( context, results.leftHandLandmarks, HAND_CONNECTIONS,
                {color: '#00CCCC', lineWidth: 5});
      drawLandmarks( context, results.leftHandLandmarks,
                {color: '#FF0000', lineWidth: 2});
      drawConnectors( context, results.rightHandLandmarks, HAND_CONNECTIONS,
                {color: '#CCCC00', lineWidth: 5});
      drawLandmarks( context, results.rightHandLandmarks,
                {color: '#FF0000', lineWidth: 2});
    
      if (this.props.debug >= 0 && this.props.debug < results.faceLandmarks.length) {
            const drawpoint = [results.faceLandmarks[this.props.debug]];
            drawLandmarks( context, drawpoint, {color: '#FFFF00', lineWidth: 2});
      }
      
      context.restore();
  }
 
  onUpdateState = (modelState,detectorState=null) => {
    if (modelState) {
      this.model_state = modelState;
    }
    if (detectorState) {
      this.detector_state = detectorState;
    }
    this.props.stateCb(this.model_state, this.detector_state);
  };

  onInferResults = (results) => {
      this.drawPreview(results);
      this.props.inferCb(results);
  };
  
  setSource = (stream, file)=>{
      console.log('setSource',stream,file);

      if (!this.videoRef.current) {
        alert('this.videoRef.current not ready');
        return;
      }
      this.videoRef.current.pause();
      this.videoRef.current.src = '';
      this.videoRef.current.srcObject = null;
      if (!stream && !file) {
        // stop
        if( this.detecte_timer ){
          clearInterval(this.detecte_timer);
          this.detecte_timer = null;
          this.onUpdateState(null,'stop');
        }
        return;
      }

      if (stream) {
        this.videoRef.current.srcObject = stream;
      } else if (file) {
        // all video file can be in ./video folder
        this.videoRef.current.src = './video/'+file;
        this.videoRef.current.loop = true;
      }
      this.videoRef.current.play().then(() => {
        console.log('Video is playing');
      }).catch((error) => {
        console.error('Failed to play video:', error);
      });

      if(!this.detecte_timer ) {
        this.onUpdateState(null,'running');
        this.detecte_timer = setInterval(this.detecte_video, this.props.interval);
      }
  }

  detecte_video = async () => {
    if (!this.videoRef.current || !this.videoRef.current.videoWidth) {
      console.log('video frame not ready.');
    } else {
      // await this.model.send({ image: this.videoRef.current});
      ModelMgr.get().infer(this.videoRef.current);
    }
  };


  render() {
    return (
      <div className={styles.detector_container}>
          <video  ref={this.videoRef} autoPlay />
          <canvas ref={this.canvasRef} width={1280} height={720} />
      </div>
    );
  }
}

export default Detector;
