import React from 'react';
import styles from '../../style/solution/VrTour.module.css';
import * as BABYLON from 'babylonjs';
import Loading from '../../utility/loading/Loading'

class VrTour extends React.Component{
    constructor(props){
        super(props);
        this.state = {
          loading_info: 'data'
        }
        this.canvasRef = React.createRef();
        this.isUserCtl = false;
      }

    render() {
        return (<div className={styles.container}>
            <Loading info={this.state.loading_info}/>
            <canvas className={styles.babylon_canvas} ref={this.canvasRef} 
                onPointerDown={(e) => {this.isUserCtl=true;}} 
                onPointerUp={(e) => {this.isUserCtl=false;}}/>
      </div>);
    }

  componentDidMount() {
        const engine = new BABYLON.Engine(this.canvasRef.current, true);
        this.scene = this.creatScene(engine);

        engine.runRenderLoop(() => {
            this.scene.render();
        });
        window.addEventListener("resize", () => {
            engine.resize();
        });
        this.setState({loading_info: null});
        this.scene.registerBeforeRender(this.updateCameraFunc);
  }
  componentWillUnmount() {
        this.scene.unregisterBeforeRender(this.updateCameraFunc);
        const engine = this.scene.getEngine();
        window.removeEventListener('resize', () => {
            engine.resize();
        });
        if (engine) {
            engine.stopRenderLoop();
        }
        if (this.scene) {
          this.scene.dispose();
        }
        if (engine) {
            engine.dispose();
        }
        if (this.playEvt) {
            clearTimeout(this.playEvt);
        }
  }


  creatScene(engine) {
        const scene = new BABYLON.Scene(engine);

        // const camera = new BABYLON.FreeCamera( "Camera", new BABYLON.Vector3(0, 0, 0), scene );
        const camera = new BABYLON.ArcRotateCamera("Camera", 0, Math.PI / 2, 3, new BABYLON.Vector3(0, 0, 0), scene);
        camera.lowerRadiusLimit = 2;
        camera.upperRadiusLimit = 50;
        camera.attachControl(this.canvasRef.current, true);

        // const hlight = new BABYLON.HemisphericLight("light", new BABYLON.Vector3(0, 0, 0), scene);
        // hlight.intensity = 1;

        this.createSkySphere(scene);

        return scene;
  }
    createSkyVideo(scene) {
        const video_url = `${process.env.PUBLIC_URL}/video/donrun.mp4`;
        const video = document.createElement('video');
        video.src = video_url;
        video.crossOrigin = 'anonymous';
        video.loop = true;
        video.muted = true;
        this.playEvt = setTimeout(() => {
            video.play();
            this.playEvt = null;}, 100);

        const videoTexture = new BABYLON.VideoTexture("videoTexture", video, scene, true);
        videoTexture.vScale = -1; 
        const sphere = BABYLON.MeshBuilder.CreateSphere("video-sphere", { diameter: 100 }, scene);
        const sphereMaterial = new BABYLON.StandardMaterial("video-sphere-material", scene);
        sphereMaterial.diffuseTexture = videoTexture;
        sphereMaterial.diffuseTexture.coordinatesMode = BABYLON.Texture.SPHERICAL_MODE;
        sphereMaterial.backFaceCulling = false;
        sphereMaterial.emissiveColor = new BABYLON.Color3(1, 1, 1);
        sphere.material = sphereMaterial;
    }

    createSkySphere(scene) {
        const env_pic1 = `${process.env.PUBLIC_URL}/image/3840.jpg`;
        const env_pic2 = `${process.env.PUBLIC_URL}/image/5760.jpg`;
        // const env_pic3 = `${process.env.PUBLIC_URL}/image/yard.webp`;

        // Create a sphere and apply the panoramic texture
        const sphere1 = BABYLON.MeshBuilder.CreateSphere("sphere1", { diameter: 1 }, scene);
        const sphereMaterial1 = new BABYLON.StandardMaterial("sphereMaterial", scene);
        const sphereTexture1 = new BABYLON.Texture(env_pic1, scene);
        sphereTexture1.vScale = -1;
        sphereTexture1.uScale = -1;
        sphereMaterial1.diffuseTexture = sphereTexture1;
        sphereMaterial1.diffuseTexture.coordinatesMode = BABYLON.Texture.SPHERICAL_MODE;
        sphereMaterial1.backFaceCulling = false;
        sphereMaterial1.emissiveColor = new BABYLON.Color3(1, 1, 1);
        sphere1.material = sphereMaterial1;
        sphere1.rotation.y = Math.PI;

        const sphere2 = BABYLON.MeshBuilder.CreateSphere("sphere2", { diameter: 100 }, scene);
        const sphereMaterial2 = new BABYLON.StandardMaterial("sphereMaterial", scene);
        const sphereTexture2 = new BABYLON.Texture(env_pic2, scene);
        sphereTexture2.vScale = -1;
        sphereMaterial2.diffuseTexture = sphereTexture2;
        sphereMaterial2.diffuseTexture.coordinatesMode = BABYLON.Texture.SPHERICAL_MODE;
        sphereMaterial2.backFaceCulling = false;
        sphereMaterial2.emissiveColor = new BABYLON.Color3(1, 1, 1);
        sphere2.material = sphereMaterial2;
    }

    updateCameraFunc = () => { 
        if (!this.isUserCtl) {
            const camera = this.scene.getCameraByName("Camera");
            camera.alpha = (camera.alpha + 0.002) % (2 * Math.PI);
        }
    };
}

export default VrTour;
