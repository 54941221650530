
import React from 'react';
import PropTypes from 'prop-types';
import styles from './Loading.module.css';

class Loading extends React.Component {
    static propTypes = {
        type: PropTypes.string,
        info: PropTypes.string,
    }
    static defaultProps = {
        type: 'default',
        info: null,
    }
    
    render() {
        if (!this.props.info) {
            return null;
        } 

        /* border: 24px dashed #f9f073;  solid double dashed dotted */
        let borderStyle = { border: '24px dashed #ff7'};
        let msgStyle = { color:'rgb(252, 70, 4)'};
        if (this.props.type === 'solid') {
            borderStyle = { border: '24px solid #fff',borderTop: '24px solid #777'};
            msgStyle = { color:'rgb(252, 70, 4)'};
        } else if (this.props.type === 'dotted') {
            borderStyle = { border: '24px dotted #ff7'};
            msgStyle = { color:'rgb(252, 70, 4)'};
        }
        // console.log(borderStyle);
        
        return (
            <div className={styles.loading_container}>
            <div className={styles.spinner} style={borderStyle}>
                <div className={styles.anti_spinner}>
                    <div className={styles.title_info} style={msgStyle}>Loading</div>
                    <div className={styles.msg_info} style={msgStyle}>{this.props.info}</div>
                </div>
            </div>
            </div>
        );
    }
}


export default Loading;
