import React from 'react';
import { Link } from 'react-router-dom';
import { asSysConfConsumer } from '../utility/hoc/Sysconf';

import styles from '../style/Navbar.module.css'

class Navbar extends React.Component {
    render() {
        if (this.props.sysConf.immerse) {
            return null;
        }
        // console.log('Navbar render',window.innerWidth);
        let context = {
            "title": "灵起科技",
            "nav-cv": "边缘视觉",
            "nav-llm": "大语言模型",
            "nav-tour" : "全景导览",
            "nav-about": "关于我们"
        };
        if (window.innerWidth < 768) {
            context = {
                "title": "灵起",
                "nav-cv": "视觉",
                "nav-llm": "大模型",
                "nav-tour" : "导览",
                "nav-about": "我们"
            };
        }
        return (
            <div className={styles.container}>
                <div>
                    <Link to="/home" className={styles.linkstyle}>
                        &nbsp;{context["title"]} 
                    </Link>
                </div>

                <div className={styles.navbar}>
                    <nav className={styles.navigator}>
                        <ul className={styles.navigator}>
                            <li><Link to="/solution/cv">{context["nav-cv"]}</Link></li>
                            <li><Link to="/solution/llm">{context["nav-llm"]}</Link></li>
                            <li><Link to="/solution/vr-tour">{context["nav-tour"]}</Link></li>
                            <li><Link to="/about">{context["nav-about"]}</Link></li>
                        </ul>
                    </nav>
                </div>
            </div>
        );
    }
}

export default asSysConfConsumer(Navbar);
