import React from 'react';
import { BrowserRouter, Route, Routes, Navigate,Outlet } from 'react-router-dom';

import { asSysConfProvider,asSysConfConsumer } from './utility/hoc/Sysconf';
import withRouterInfo from './utility/hoc/Routerinfo';

import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './components/Home';
import About from './components/About';

import SolutionCv from './components/solutions/CV';
import SolutionLlm from './components/solutions/LLM';
import SolutionVrTour from './components/solutions/VrTour';

import Game from './components/explore/Game';
import Guide from './components/explore/Guide';
import Philosophy from './components/explore/Philosophy';

import styles from './style/App.module.css';


class Main extends React.Component {
    constructor(props) {
        super(props);
        // init sys config from search param
        if (this.props.route.location.search) {
            const queryParams = new URLSearchParams(this.props.route.location.search);
            const immerse = queryParams.has('immerse');
            if (immerse) {
                this.props.updateSysConf({immerse: true});
            }
            const theme = queryParams.get('theme');
            if (theme) {
                this.props.updateSysConf({theme: theme});
            }
        }
    }
    componentDidMount() {
        window.addEventListener('keydown', this.handleKeyDown);
        // console.log('App componentDidMount');
    }
    componentWillUnmount() {
        window.removeEventListener('keydown', this.handleKeyDown);
        // console.log('App componentWillUnmount');
    }

    handleKeyDown = (e) => {
        if (e.key === 'Escape') {
            e.preventDefault();
            this.props.updateSysConf({immerse: !this.props.sysConf.immerse});
        } else if(e.key === 'F10') {
            console.log('route',this.props.route);
            console.log('sysConf',this.props.sysConf);
        } else if(e.key === 'F1') {
            this.props.route.navigate('/home');
        } else if(e.key === 'F2') {
            this.props.route.navigate('/about');
        } else if(e.key === 'F3') {
            this.props.route.navigate('/explore/guide');
        } else if(e.key === 'F4') {
            this.props.route.navigate('/explore/game');
        } else if(e.key === 'F5') {
            this.props.route.navigate('/explore/philosophy');
        } else if(e.key === 'F6') {
            this.props.route.navigate('/solution/vr-tour');
        }
        console.log('Main handleKeyDown:',e.key);
    }

    render() {
        return (
            <div tabIndex='0' className={styles.App}>
                <Navbar/>
                <Routes>
                    <Route path="*" element={<Navigate to="/home" />}></Route>
                    <Route path="/home" element={<Home/>} />
                    <Route path="/about" element={<About/>} />
                    <Route path="/explore" element={<Outlet />}>
                        <Route path="guide" element={<Guide/>} />
                        <Route path="game" element={<Game/>} />
                        <Route path="philosophy" element={<Philosophy/>} />
                    </Route>
                    <Route path="/solution" element={<Outlet/>}>
                        <Route path="cv" element={<SolutionCv/>} />
                        <Route path="llm" element={<SolutionLlm/>} />
                        <Route path="vr-tour" element={<SolutionVrTour/>} />
                    </Route>
                </Routes>
                <Footer/>
            </div>
        );
    }
}



class App extends React.Component {
    render() {
        const WrappedMain = asSysConfConsumer(withRouterInfo(Main));
        const route_base = process.env.PUBLIC_URL;
        return (
            <BrowserRouter basename={route_base}>
                <WrappedMain/>
            </BrowserRouter>
        );
    }
}

export default asSysConfProvider(App);
