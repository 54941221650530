import React from 'react';
import { Link } from 'react-router-dom';
import styles from '../../style/explore/Guide.module.css';

class Guide extends React.Component {
    constructor(props) {
        super(props);
        this.context_idx = 0;
        this.interval = null;
    }
    componentDidMount() {
        const note_str = `注意: 游戏需要开启摄像头哟, 
用户张嘴吐出一个火球,撞击方块后方块爆炸。
直到方块全部消失,你就赢啦! 
可reset重新开始玩。
可按Esc键进入全屏模式。
开始挑战吧。`;
        const container = document.getElementById('guide_container');
        const center_x = container.offsetWidth/2;
        const center_y = container.offsetHeight/2;
        this.interval = setInterval(() => {
            if (this.context_idx < note_str.length) {
                let pElem = document.createElement('p');
                document.getElementById('content').appendChild(pElem);
                pElem.innerHTML = note_str[this.context_idx];
                pElem.className = styles.rotate_txt;
                pElem.style.top = `${center_y - 9}px`;
                pElem.style.left = `${center_x - 9}px`;
                console.log(this.context_idx, note_str[this.context_idx]);
                this.context_idx++;
            } else {
                clearInterval(this.interval);
                this.interval = null;
            }
        }, 400);
    }
    componentWillUnmount() {
        this.interval && clearInterval(this.interval);
    }

    render() {
        return (
            <div id='guide_container' className={styles.container}>
                <svg className={styles.background} viewBox="0 0 100 100" fill="none">
                    <circle className={styles.zoom_circle} vectorEffect="non-scaling-stroke" cx="50" cy="50" r="50"/>
                    <circle className={styles.zoom_circle} vectorEffect="non-scaling-stroke" cx="50" cy="50" r="50"/>
                    <circle className={styles.zoom_circle} vectorEffect="non-scaling-stroke" cx="50" cy="50" r="50"/>
                    <circle className={styles.zoom_circle} vectorEffect="non-scaling-stroke" cx="50" cy="50" r="50"/>
                    <circle className={styles.zoom_circle} vectorEffect="non-scaling-stroke" cx="50" cy="50" r="50"/>
                    <circle className={styles.zoom_circle} vectorEffect="non-scaling-stroke" cx="50" cy="50" r="50"/>
                    <circle className={styles.zoom_circle} vectorEffect="non-scaling-stroke" cx="50" cy="50" r="50"/>
                    <circle className={styles.zoom_circle} vectorEffect="non-scaling-stroke" cx="50" cy="50" r="50"/>
                    <circle className={styles.zoom_circle} vectorEffect="non-scaling-stroke" cx="50" cy="50" r="50"/>
                </svg>
                <div className={styles.titlediv}>
                    <h1 className={styles.titlename}>吐火球游戏</h1>
                </div>
                <div id='content' className={styles.content}>
                    {/* <h3>注意：游戏需要开启摄像头哦</h3> */}
                </div>
                <div className={styles.button_container}>
                    <svg className={styles.startsvg} viewBox="0 0 100 100" >
                            <circle vectorEffect="non-scaling-stroke" cx="50" cy="50" r="50" stroke="black"
                                strokeDasharray="0 9" strokeLinecap="round" 
                                strokeWidth="2"></circle>
                    </svg>
                    <Link to="/explore/game">
                        <h1 className={styles.start}>start</h1>
                    </Link>
                </div>
            </div>
        );
    }
}
export default Guide;
