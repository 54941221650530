import React from 'react';
import styles from '../style/About.module.css';

function About() {
    return (
        <section className={styles.container}>
            <div className={styles.titlediv}>
                <h1 className={styles.titlename}>北京灵起科技有限公司</h1>
                <img className={styles.badge} src={`${process.env.PUBLIC_URL}/image/lite-badge.jpg`} alt='nvidia certify'></img>
            </div>
            <div className={styles.certifydiv}>
                <img className={styles.certifyimg} src={`${process.env.PUBLIC_URL}/image/ggx.jpg`} alt='national certify'></img>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <img className={styles.certifyimg} src={`${process.env.PUBLIC_URL}/image/cgx.jpg`} alt='park certify'></img>
            </div>
            <hr/>
            <div className={styles.service}>
                <p>成立于2017年, 国家高新技术企业, 中关村高新技术企业。
                    团队成员来自百度、腾讯、重庆大学、清华大学、北京理工大学、北京邮电大学等。
                    专注做边缘设备上的机器视觉方案开发和大模型解决方案开发，目前已经在中国，美国，法国等世界各有多个落地方案。
                </p>
                <br/>
                <h3>我们提供：机器视觉，大语言模型，边缘开发，全景视频导览等方向的技术服务。</h3>
                <p className={styles.servicelist}>系统设计咨询服务</p>
                <p className={styles.servicelist}>算法模型训练服务</p>
                <p className={styles.servicelist}>软件系统开发服务</p>
            </div>
            <hr/>
            <div className={styles.history}>
                <h2>历史荣誉</h2>
                <p>百度大数据Hackthon大赛黑马奖。</p>
                <p>腾讯音箱独家资讯合成技术服务商。</p>
                <p>阿里AI赛道明星班成员。</p>
                <p>人⺠日报社深度技术合作单位。</p>
                <p>英伟达Jetson ISV,软件算法官方合作伙伴。</p>
            </div>
            <hr/>
            <div className={styles.contact}>
                <h2>联系我们</h2>
                <p>Email: bd@lingqi.tech</p>
                <p>地址1: 北京市朝阳区望京soho, T2 C座 1705</p>
                <p>地址2: 北京市通州区台湖镇兴光四街1号院绿色智慧科技创新示范园A座406</p>
                <p>地址3: 重庆市渝北区大竹林金科乐方3号楼11-12</p>
            </div>
        </section>
    );
}

export default About;