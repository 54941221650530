import React from 'react';
import { Link } from 'react-router-dom';

import styles from '../style/Home.module.css';

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    render() {
        return (
            <div className={styles.container}>
                <img className={styles.background_img} src={`${process.env.PUBLIC_URL}/image/mountain.jpg`} alt='background'></img>
                <svg className={styles.background_svg} viewBox="0 0 100 100" fill="none">
                    <circle className={styles.circle + ' ' + styles.speed1} vectorEffect="non-scaling-stroke" cx="50" cy="50" r="50"/>
                    <circle className={styles.circle + ' ' + styles.speed2} vectorEffect="non-scaling-stroke" cx="50" cy="50" r="40"/>
                    <circle className={styles.circle + ' ' + styles.speed3} vectorEffect="non-scaling-stroke" cx="50" cy="50" r="30"/>
                    <circle className={styles.circle + ' ' + styles.speed4} vectorEffect="non-scaling-stroke" cx="50" cy="50" r="20"/>
                    <circle className={styles.circle + ' ' + styles.speed5} vectorEffect="non-scaling-stroke" cx="50" cy="50" r="10"/>
                </svg>
                <div className={styles.content}>
                    <Link to="/explore/guide" className={styles.linkstyle}>
                        <h1 className={styles.head}>数灵起源</h1>
                    </Link>
                    <p className={styles.hint}>☝数字孪生小游戏</p>
                    <br/>
                    <Link to="/explore/philosophy" className={styles.linkstyle}>
                        <p className={styles.text}>数字意识觉醒</p>
                        <p className={styles.text}>和物理世界交错孪生</p>
                    </Link>
                    <p className={styles.hint}>☝AI的哲学内审</p>
                </div>
            </div>
        );
    }
}
export default Home;
