
import React from 'react';
import * as BABYLON from 'babylonjs';
import * as GUI from '@babylonjs/gui';
import * as CANNON from "cannon";
import Monitor from '../../utility/monitor/Monitor'
import Loading from '../../utility/loading/Loading'
import { asSysConfConsumer } from '../../utility/hoc/Sysconf';

import styles from '../../style/explore/Game.module.css';

class Game extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      spin: true,
      indicator: 'green',
      loading_info: 'data'
    }
    this.boxs = [];
    this.canvasRef = React.createRef();
  }

  render() {
    let cover = 
      <div className={styles.cover_container}> 
        <img src={process.env.PUBLIC_URL+'/image/spin.png'} alt='spin' 
          className={styles.cover_spin} onClick={this.onSpin}/> 
        <button className={styles.reset_button} onClick={this.resetGame}>reset</button>
        <div id="indicator" className={styles.indicator} style={{backgroundColor:this.state.indicator}}/>
        <button className={styles.test_ball_button} onClick={()=>{
            const xVelocity = Math.random() * 10 - 5;
            const yVelocity = Math.random() * 10 - 5;
            const zVelocity = Math.random() * 10 - 5;
            const velocity = new BABYLON.Vector3(xVelocity, yVelocity, zVelocity);
            const position = new BABYLON.Vector3(Math.random() * 10 - 5, 8, Math.random() * 10 - 5);
            this.generateBall(position, velocity);
          }}>test ball</button>
      </div>;
    if (this.props.sysConf.immerse) {
        cover = null;
    }

    return (
      <div className={styles.container}>
          <Loading info={this.state.loading_info}/>
          <Monitor  hide={this.props.sysConf.immerse} 
                    onState={(data) => {this.setState({loading_info: data});}}
                    onMsg={this.onFaceProc2} /> 
          <div className={styles.babylon_container}>
            {cover}
            <canvas className={styles.babylon_canvas} ref={this.canvasRef}></canvas>
          </div>
      </div>);
  }

  componentDidMount() {
    const engine = new BABYLON.Engine(this.canvasRef.current, true);
    this.scene = this.creatScene(engine);
    
    engine.runRenderLoop(() => {
      this.scene.render();
    });
 
    window.addEventListener("resize", () => {
      engine.resize();
    });
  }
  componentWillUnmount() {
    this.scene.unregisterBeforeRender(this.updateCameraFunc);
    // if (this.scene) {
    //   this.scene.dispose();
    // }
    if (this.engine) {
      this.engine.dispose();
    }
  }
  componentDidUpdate(prevProps, prevState) {
    this.scene.unregisterBeforeRender(this.updateCameraFunc);
    if (this.state.spin) {
        this.scene.registerBeforeRender(this.updateCameraFunc);
    } 
  }
  updateCameraFunc = () => { 
      this.camera.alpha = (this.camera.alpha + 0.001) % (2 * Math.PI);
  };

  onSpin = (e) => {
    if (this.state.spin) {
        e.target.style.filter = 'grayscale(100%)';
    } else {
        e.target.style.filter = 'grayscale(0%)';
    } 
    this.setState({spin:!this.state.spin});
}

  createSkyBox() {
    const skybox = BABYLON.MeshBuilder.CreateBox("skyBox", {size:200.0}, this.scene);
    const skyMat = new BABYLON.StandardMaterial("skyMat", this.scene);
    skyMat.backFaceCulling = false;
    const files = [`${process.env.PUBLIC_URL}/image/space/left.jpg`, 
                  `${process.env.PUBLIC_URL}/image/space/up.jpg`, 
                  `${process.env.PUBLIC_URL}/image/space/front.jpg`,
                  `${process.env.PUBLIC_URL}/image/space/right.jpg`, 
                  `${process.env.PUBLIC_URL}/image/space/down.jpg`, 
                  `${process.env.PUBLIC_URL}/image/space/back.jpg`];
    skyMat.reflectionTexture = new BABYLON.CubeTexture.CreateFromImages(files, this.scene);
    skyMat.reflectionTexture.coordinatesMode = BABYLON.Texture.SKYBOX_MODE;
    skyMat.diffuseColor = new BABYLON.Color3(0, 0, 0);
    skyMat.specularColor = new BABYLON.Color3(0, 0, 0);
    skyMat.disableLighting = true;
    skybox.material = skyMat;

    const ground = BABYLON.MeshBuilder.CreateGround("ground", {width: 180, height: 180}, this.scene);
    const groundMaterial = new BABYLON.StandardMaterial("groundMaterial", this.scene);
    const ground_pic = `${process.env.PUBLIC_URL}/image/mountain.jpg`;
    groundMaterial.diffuseTexture = new BABYLON.Texture(ground_pic, this.scene);
    groundMaterial.specularColor = new BABYLON.Color3(0, 0, 0); // 设置为黑色
    groundMaterial.specularPower = 0; 
    ground.material = groundMaterial;
    ground.position.y = -2;
    ground.physicsImpostor = new BABYLON.PhysicsImpostor(ground, 
      BABYLON.PhysicsImpostor.BoxImpostor, { mass: 0, friction: 0.5, restitution: 0.3 }, this.scene);
  }

  resetGame = () => {
      for (const box of this.boxs){
        box.dispose();
      }
      this.boxs = [];
      const boxCnt = 4 + Math.floor(Math.random() * 3);
      for (let i = 0; i < boxCnt; i++) {
        const box = BABYLON.MeshBuilder.CreateBox('Box-' + i, { size:2 }, this.scene);
        const material = new BABYLON.StandardMaterial('Box-' + i +"-Material", this.scene);
        material.emissiveColor = new BABYLON.Color3(0.2, 0.5, 0.2);
        box.material = material;
        box.position.y = -1;
        box.position.x = Math.random() * 20 - 10;
        box.position.z = Math.random() * 20 - 10;
        box.physicsImpostor = new BABYLON.PhysicsImpostor(box, 
          BABYLON.PhysicsImpostor.BoxImpostor, { mass: 1, friction: 0.3, restitution: 0.4 }, this.scene);
        box.physicsImpostor.setLinearVelocity(new BABYLON.Vector3(Math.random() * 20 - 10, 0, Math.random() * 20 - 10));
        this.boxs.push(box);
      }
  }

  generateBall(initposition,initialVelocity) {
      // const targetSphere = scene.getMeshByName("mySphere");
      // const velocity = targetSphere.physicsImpostor.getLinearVelocity();

      // Create a sphere
      const sphere_name = "sphere-" + Math.random();
      const sphere = BABYLON.MeshBuilder.CreateSphere(sphere_name, { diameter: 1 }, this.scene);
      sphere.position = initposition;
      const material = new BABYLON.StandardMaterial(sphere_name+'-Material', this.scene);
      material.emissiveColor = new BABYLON.Color3(0.4, 0, 0);
      material.intensity = 0.5;
      sphere.material = material;
      // sg.addShadowCaster(sphere);

      // Add physics to the sphere
      sphere.physicsImpostor = new BABYLON.PhysicsImpostor(sphere, BABYLON.PhysicsImpostor.SphereImpostor, { mass: 1, restitution: 0.2}, this.scene);
      sphere.physicsImpostor.setLinearVelocity(initialVelocity);

        let boxImpostors = [];
        for( const box of this.boxs) {
          boxImpostors.push(box.physicsImpostor);
        }

        const game = this;
        function releaseBox (Impostor) {
          const target = Impostor.object;
          Impostor.dispose();
          console.log(game.boxs);
          let indexToRemove = game.boxs.indexOf(target);
          if (indexToRemove !== -1) {
            game.boxs.splice(indexToRemove, 1);
            if (game.boxs.length === 0) {
              const advancedTexture = GUI.AdvancedDynamicTexture.CreateFullscreenUI("UI",true,game.scene);
              const textBlock = new GUI.TextBlock();
              textBlock.text = "🎉 Celebrate your winning! 🎉";
              textBlock.color = "white";
              textBlock.fontSize = 50;
              textBlock.textHorizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_CENTER;
              textBlock.textVerticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_CENTER;
              advancedTexture.addControl(textBlock);
              setTimeout(() => { 
                advancedTexture.removeControl(textBlock);
                textBlock.dispose();
                advancedTexture.dispose();}, 5000);
            }
          }
          console.log(game.boxs);
          target.dispose();
        };


        sphere.physicsImpostor.registerOnPhysicsCollide(boxImpostors, function(mainImpostor, collidedImpostor) {
          console.log('collide:',mainImpostor.object.name, collidedImpostor.object.name);
          const explosion = new BABYLON.PointLight("explosionLight", sphere.position, this.scene);
          explosion.diffuse = new BABYLON.Color3(1, 0, 0);
          explosion.intensity = 10;
          setTimeout(() => explosion.dispose(), 300); 
          mainImpostor.dispose();
          sphere.dispose();
          releaseBox(collidedImpostor);
        });
        
      // fire
      const particleSystem = new BABYLON.ParticleSystem("fire particles", 2000, this.scene);
      particleSystem.particleTexture = new BABYLON.Texture("https://playground.babylonjs.com/textures/flare.png", this.scene);
      
      // 粒子颜色
      particleSystem.color1 = new BABYLON.Color4(0.7, 0.8, 1.0, 1.0);
      particleSystem.color2 = new BABYLON.Color4(0.2, 0.5, 1.0, 0.3);
      particleSystem.colorDead = new BABYLON.Color4(0, 0, 0.2, 0.0);

      // 粒子大小
      particleSystem.minSize = 0.1;
      particleSystem.maxSize = 0.2;

      // 粒子寿命
      particleSystem.minLifeTime = 0.3;
      particleSystem.maxLifeTime = 1.5;

      // 发射速率
      particleSystem.emitRate = 500;
      particleSystem.emitter = sphere;
      var emitter = new BABYLON.HemisphericParticleEmitter(0.5,0);
      particleSystem.particleEmitterType = emitter;

      // 重力
      particleSystem.gravity = new BABYLON.Vector3(0, -0.1, 0);

      // 粒子速度
      particleSystem.minEmitPower = 1;
      particleSystem.maxEmitPower = 2;
      particleSystem.updateSpeed = 0.01;

      particleSystem.start();
      return sphere_name;
  }

  creatScene(engine) {
    this.scene = new BABYLON.Scene(engine);
    const gravityVector = new BABYLON.Vector3(0, -9.81, 0);
    const physicsPlugin = new BABYLON.CannonJSPlugin(true, 10, CANNON);
    this.scene.enablePhysics(gravityVector, physicsPlugin);

    this.camera = new BABYLON.ArcRotateCamera("Camera", -Math.PI / 2, Math.PI / 2.5, 40, new BABYLON.Vector3(0, 0, 0), this.scene);
    this.camera.attachControl(this.canvasRef.current, true);
    this.camera.lowerRadiusLimit = 2;
    this.camera.upperRadiusLimit = 99;

    const plight = new BABYLON.PointLight("plight", new BABYLON.Vector3(0, 100, 0), this.scene);
    plight.intensity = 3;
    const slight = new BABYLON.SpotLight("slight", new BABYLON.Vector3(0, 30, 0), 
      new BABYLON.Vector3(0, -1, 0), Math.PI/4, 2, this.scene);
    slight.intensity = 0.5;

    this.createSkyBox();
    this.resetGame();

    if (this.state.spin) {
      this.scene.registerBeforeRender(this.updateCameraFunc);
    }
    return this.scene;
  }


  onArmProc = (msg) => {
    // console.log('Game onArmProc',msg);
    const min_gap = 0.1;
    if (!msg || !msg.poseLandmarks) {
      return;
    }
    const pose = msg.poseLandmarks;
    var left_arm = null;
    var right_arm = null;
    // right hand 16 - 14,  left hand 15 - 13
    if (pose[11]['visibility'] > 0.8 && pose[15]['visibility'] > 0.8 ) {
      const delta_x = pose[15]['x'] - pose[13]['x'];
      const delta_y = pose[13]['y'] - pose[15]['y'];
      // console.log('left hand',delta_x,delta_y);
      if (delta_x > min_gap && delta_y < min_gap) {
        left_arm = 'left';
      } 
      if (delta_x < min_gap && delta_y > min_gap) {
        left_arm = 'up';
      } 
    }
    if (pose[12]['visibility'] > 0.8 && pose[16]['visibility'] > 0.8) {
      const delta_x = pose[14]['x']-pose[16]['x'];
      const delta_y = pose[14]['y']-pose[16]['y'];
      // console.log('right hand',delta_x,delta_y);
      if (delta_x > min_gap && delta_y < min_gap) {
        right_arm = 'right';
      } 
      if (delta_x < min_gap && delta_y > min_gap) {
        right_arm = 'up';
      }
    }
    if (left_arm === 'up' && right_arm === 'up') {
      this.camera.beta -= 0.05;
    } else if (left_arm === 'left' && right_arm === 'right') {
      this.camera.beta += 0.05;
    } else if (left_arm === 'left') {
      this.camera.alpha += 0.05;
    } else if (right_arm === 'right') {
      this.camera.alpha -= 0.05;
    }
    console.log('onArmProc',left_arm,right_arm);
  };

  onFaceProc2 = (msg) => {
      // left & right eye angle 33 263
      // top and bottom point 10, 152
      // lip up bottom 13, 14
    if (!msg || !msg.faceLandmarks) {
      return;
    }
    const upper_lip = msg.faceLandmarks[13]
    const lower_lip = msg.faceLandmarks[14]
    const top_point = msg.faceLandmarks[10];
    const bottom_point = msg.faceLandmarks[152];
    const lip_gap = Math.abs(upper_lip.y - lower_lip.y)
    const total_gap = Math.abs(top_point.y - bottom_point.y);
    // console.log('onFaceProc lip_power:',lip_gap, total_gap);
    if (!this.timer && lip_gap > (total_gap/10)) {
      // console.log("face:", msg.faceLandmarks[33],msg.faceLandmarks[263]);
      const left_eye = new BABYLON.Vector3(msg.faceLandmarks[33]['x'] - 0.5, 1-msg.faceLandmarks[33]['y'], -msg.faceLandmarks[33]['z']);
      const right_eye = new BABYLON.Vector3(msg.faceLandmarks[263]['x'] - 0.5, 1-msg.faceLandmarks[263]['y'], -msg.faceLandmarks[263]['z']);
      const top = new BABYLON.Vector3(top_point['x'] - 0.5, 1-top_point['y'], -top_point['z']);
      const bottom = new BABYLON.Vector3(bottom_point['x'] - 0.5, 1-bottom_point['y'], -bottom_point['z']);

      const D1 = left_eye.subtract(right_eye);
      const D2 = top.subtract(bottom);

      const faceVect = BABYLON.Vector3.Cross(D1, D2).normalize();
      faceVect.y *= -1;
      const postion = this.camera.position;
        const velocity = postion.scale(-1);
        // const velocity = postion.normalize().scale(-1).subtract(faceVect).scale(100*lip_gap);
        // velocity.x += faceVect['x'];
        // velocity.y += faceVect['y'];
        // velocity.z *= 1.5;
        console.log("init velocity:", postion,velocity);

        this.generateBall(postion,velocity);
        this.setState({indicator: 'yellow'});
        this.timer = setTimeout(() => {
          this.timer = null;
          this.setState({indicator: 'green'});
        }, 1000);
    }
  };

  onFaceProc = (msg) => {
    if (!msg || !msg.multiFaceGeometry || msg.multiFaceGeometry.length === 0) {
      return;
    }
    const mesh = msg.multiFaceGeometry[0].getMesh();
    const vertexbuf = mesh.getVertexBufferList();
    // Position (XYZ) + Texture (UV) but we only need Position

    const indexbuf = mesh.getIndexBufferList();
    var facepoints = [];
    var faceuv = [];
    for (let i = 0; i < vertexbuf.length; i += 5) {
      facepoints.push(vertexbuf[i]);
      facepoints.push(vertexbuf[i]+1);
      facepoints.push(vertexbuf[i]+2);
      faceuv.push(vertexbuf[i+3]);
      faceuv.push(vertexbuf[i+4]);
    }

    // if (this.faceMash) {
    //   this.faceMash.dispose();
    // }
    // // console.log(facepoints);
    // this.faceMash = BABYLON.MeshBuilder.CreateTube("tube", { path: facepoints, radius: 1, side: BABYLON.Mesh.DOUBLESIDE }, this.scene);
	
	  // //Set arrays for positions and indices
    // var positions = []
    // for (let p of msg.faceLandmarks) {
    //   positions.push(p['x'],p['y'],p['z']);
    // }
    // var indices = [1,2,3]; // define all face of mesh.
    // var positions = [-5, 2, -3, -7, -2, -3, -3, -2, -3, 5, 2, 3, 7, -2, 3, 3, -2, 3];
    // var indices = [0, 1, 2, 2, 3, 4, 5,4,3];
    
    //Create a vertexData object
    var vertexData = new BABYLON.VertexData();

    //Assign positions and indices to vertexData
    vertexData.positions = facepoints;
    vertexData.indices = indexbuf;	
    vertexData.uvs = faceuv;

    if (this.faceMash) {
      this.faceMash.dispose();
    }
    this.faceMesh = new BABYLON.Mesh("face-mesh", this.scene);
    vertexData.applyToMesh(this.faceMesh,true);
     
    // const texture = new BABYLON.Texture('path_to_your_texture_image', scene);
    // faceMesh.material = new BABYLON.StandardMaterial('faceMaterial', scene);
    // faceMesh.material.diffuseTexture = texture;
  }


}
 
export default asSysConfConsumer(Game);
